
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function garageVehicleWidgetRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                    var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                    var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                    var isPopularField = popularEntries.length && unpopularEntries.length;
                    return [
                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                            'value': '',
                            'key': 'null-option'
                        }, '\n          ', this.title, '\n        ') : null,
                        this.loading ? _createElement('option', {
                            'key': 'loading-option',
                            'disabled': true
                        }, '...loading...') : null,
                        isPopularField ? [
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '15141'
                            }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                            _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '15144'
                            }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                        ] : null,
                        _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                    ];
                }
                function scopeShowSelectedValues4() {
                    var showSelectedValues = this.selectedEntries.length;
                    return _createElement('option', {
                        'key': '_current',
                        'value': '_current'
                    }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                }
                function repeatEntry5(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, _createElement('div', {
                        'className': 'cm_dropdown_value',
                        'role': 'option',
                        'aria-selected': entry.selected
                    }, entry.value));
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'aria-label': `${ this.title } ${ this.selectedEntries.map(e => e.value).join(', ') }`
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty' + (this.disabled && !this.firstSelectedTerm ? ' disabled' : '') + (this.isDropdownVisible ? ' cropped' : ''),
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'tabIndex': this.disabled ? '-1' : '0',
                    'role': 'combobox',
                    'aria-haspopup': 'listbox',
                    'aria-autocomplete': 'none',
                    'aria-controls': `${ this.title }-combobox`,
                    'aria-selected': this.selectedEntries.length > 0
                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null), [_createElement('svg', {
                        'className': 'icon-chevron' + (this.isDropdownVisible ? ' rotated' : ''),
                        'width': '11',
                        'height': '7',
                        'viewBox': '0 0 11 7',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'aria-hidden': 'true',
                        'key': '28690'
                    }, _createElement('path', {
                        'fillRule': 'evenodd',
                        'clipRule': 'evenodd',
                        'd': 'M1.11494 0.5L4.87165 4.67412C4.97096 4.78446 5.14398 4.78446 5.2433 4.67412L9 0.5L10.1149 1.50345L6.35824 5.67756C5.66306 6.44998 4.45188 6.44998 3.75671 5.67756L0 1.50345L1.11494 0.5Z',
                        'fill': '#1A1717'
                    }))]), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'role': 'listbox',
                        'key': '3361'
                    },
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    function onKeyDown2(e) {
        {
            if (e.type === 'keydown' && e.key === 'Enter') {
                this.selectVehicle();
            }
        }
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__home cm_vehicle-widget__garage-header' }, this.template === 'active' ? _createElement('fieldset', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '91'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement('legend', { 'aria-label': 'Shop by vehicle form' }, 'SHOP BY VEHICLE')), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ])), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button',
        'onClick': this.selectVehicle,
        'onKeyDown': onKeyDown2.bind(this),
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': '0'
    }, '\n      ADD\n    ')) : null);
}
        export const componentNames = []