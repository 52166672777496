
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function scopeOptionArrTitleUrl2(option, optionIndex) {
                    var optionArr = option.split('|');
                    var title = optionArr[0];
                    var url = optionArr[1];
                    return [_createElement('a', {
                            'className': 'swatches__form--label h7 pt11 pb8 px16 mb0',
                            'aria-label': title,
                            'href': url,
                            'key': '12751'
                        }, '\n                  ', title, '\n                ')];
                }
                function repeatOption3(option, optionIndex, optionArr, title, url) {
                    return scopeOptionArrTitleUrl2.apply(this, [
                        option,
                        optionIndex
                    ]);
                }
                return _createElement('li', {
                    'className': 'splide__slide',
                    'data-product-sku': this.removeHTML(this.sku)
                }, _createElement('div', { 'className': 'product-card a-center' }, _createElement('div', { 'className': 'product-card-top' }, _createElement('h2', mergeProps({ 'className': 'product-card__sku h7 a-left mb23 p0' }, { dangerouslySetInnerHTML: { __html: this.sku } })), _createElement('div', { 'className': 'product-card__imageContainer mb4' }, _createElement('a', {
                    'href': this.url,
                    'title': this.removeHTML(this.title),
                    'tabIndex': '-1'
                }, _createElement('div', { 'className': 'product-card__img-wrapper image-ratio--square' }, _createElement('div', { 'className': 'box-ratio' }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'loading': 'lazy'
                }))))), _createElement('div', { 'className': 'product-card__textContainer' }, _createElement('a', mergeProps({
                    'className': 'product-card__itemTitle h5 mt24 mb0',
                    'href': this.url
                }, { dangerouslySetInnerHTML: { __html: this.title } })), !this.discontinued ? _createElement('span', {
                    'className': 'b-border mb24 mt18',
                    'key': '916'
                }) : null), this.option_snippets ? _createElement('div', {
                    'className': 'product-card__swatches',
                    'key': '998'
                }, _createElement('div', { 'className': 'h7 sb-grey mb0' }, this.option_name), _createElement('div', { 'className': 'mt8' }, _createElement('div', { 'className': 'swatches swatches__container mb16' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'swatches__options-wrapper' },
                    _map(this.option_snippets, repeatOption3.bind(this))
                ])))) : null, this.many_options ? _createElement('a', {
                    'href': this.url,
                    'className': 'product-card__options-link mt8 mb16',
                    'key': '1679'
                }, '\n        See More Options\n      ') : null)), _createElement('div', { 'className': 'product-card-bottom mt4' }, _createElement('div', { 'className': 'product-card__itemPrice mb6 sb-red' }, _createElement('div', { 'className': 'price' }, _createElement('div', { 'className': 'price__container' }, _createElement('div', { 'className': 'price__regular' }, _createElement('div', { 'className': 'price__currency-wrap' }, _createElement('div', {}, _createElement('span', { 'className': 'price-item--regular typo__body-2 sb-red' }, '\n                  ', this.price_varies ? `From ${ this.formatPrice(this.price) }` : this.formatPrice(this.price), '\n                '), _createElement('span', { 'className': 'h7 m0 p0 sb-red' }, 'USD')))))))), _createElement('div', { 'className': 'cut-corner' }));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products container' }, _createElement('div', { 'className': 'product-recommendations__subheader col-12 pb20 a-center' }, _createElement('p', { 'className': 'h7 b-border m0 pb8' }, 'Related')), _createElement('h2', { 'className': 'h1 product-recommendations__header section_heading a-center col-12' }, 'You May Also Like'), _createElement('div', { 'className': 'col-12' }, _createElement.apply(this, [
        'ul',
        { 'className': 'splide__list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ])));
}
        export const componentNames = []